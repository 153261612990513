import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Users from './Users';
import Header from './Header';
import Footer from './Footer';
import AddUser from './AddUser';

function App() {

  return (
    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Users />} />
          <Route path="/add" element={<AddUser />} />
          <Route path="/update/:id" element={<AddUser />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div >
  );
}

export default App;
