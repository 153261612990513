

function Footer() {
    return (
        <footer id="footer" class="footer">
            <div class="copyright">
                &copy; Copyright <strong><span>sahahardware</span></strong>. All Rights Reserved
            </div>
        </footer>
    );
}

export default Footer;