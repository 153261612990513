import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "./constants";

const AddUser = () => {
    const { id } = useParams();
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [age, setAge] = useState(0);

    const getUser = async () => {
        try {
            const res = await axios.get(URL + `/${id}`);
            const user = res?.data[0];
            setName(user?.name);
            setAge(user?.age);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    const handleClick = async (e) => {
        e.preventDefault();
        const user = {name, age};
        try {
            if(id)
                await axios.put(URL + `/${id}`, user);
            else
                await axios.post(URL, user);
            navigate("/");
        } catch (err) {
            console.log(err);
            setError(true)
        }
    };

    return (
        <main id="main" class="main">
            <section class="table_outer">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div class="card">
                                            <div class="card-body">
                                                <form class="row g-3">
                                                    <div class="col-md-12">
                                                        <label for="userName" class="form-label">Name</label>
                                                        <input type="text" class="form-control" id="userName" name="name" 
                                                            value={name} onChange={(e)=> setName(e.target.value)} />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="userAge" class="form-label">Age</label>
                                                        <input type="number" class="form-control" id="userAge" name="age" 
                                                            value={age} onChange={(e)=> setAge(e.target.value)} />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="inputPassword5" class="form-label">Email</label>
                                                        <input type="email" class="form-control" id="inputPassword5" />
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="inputAddress5" class="form-label">Address</label>
                                                        <input type="text" class="form-control" id="inputAddres5s" placeholder="1234 Main St" />
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="inputAddress2" class="form-label">Address 2</label>
                                                        <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="inputCity" class="form-label">City</label>
                                                        <input type="text" class="form-control" id="inputCity" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="inputState" class="form-label">State</label>
                                                        <select id="inputState" class="form-select">
                                                            <option selected>Choose...</option>
                                                            <option>Chandigarh</option>
                                                            <option>Haryana</option>
                                                            <option>Maharashtra</option>
                                                            <option>Odisha</option>
                                                            <option>Punjab</option>
                                                            <option>West Bengal</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label for="inputZip" class="form-label">Zip</label>
                                                        <input type="text" class="form-control" id="inputZip" />
                                                    </div>

                                                    <div class="text-center">
                                                        <button type="submit" class="btn btn-primary" onClick={handleClick}>Save</button>
                                                        <button type="reset" class="btn btn-secondary">Reset</button>
                                                        <button type="reset" class="btn btn-secondary" onClick={()=> navigate("/")}>See All Users</button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    );
};

export default AddUser;