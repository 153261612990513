import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { URL } from './constants';

function Users() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await axios.get(URL)
                console.log(res);
                setUsers(res?.data);
            } catch (err) {
                console.log(err);
            }
        }
        getUsers();
    }, []);

    const handleClick = async (e) => {
        e.preventDefault();
        navigate("/add");
    };

    const viewUser = async(id) => {
        navigate(`/update/${id}`);
    }

    const deleteUser = async (id) => {
        try {
            await axios.delete(URL + `/${id}`);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <main id="main" class="main">
            <section class="table_outer">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <button type="button" class="btn btn-success btn-sm px-2" style={{ marginLeft: '77rem' }}
                                            onClick={handleClick}
                                        >
                                            <i class="bi bi-plus-circle-fill"></i>
                                        </button>
                                        <table class="table table-hover mb-0 ">
                                            <thead class="table-light">
                                                <tr>
                                                    <th scope="col">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value=""
                                                                id="select_all" />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">AGE</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users?.map((u, i) => (
                                                    <tr key={i}>
                                                        <th scope="row">
                                                            <div class="form-check">
                                                                <input class="form-check-input checkbox" type="checkbox" value=""
                                                                    id="flexCheckDefault1" />
                                                            </div>
                                                        </th>
                                                        <td>{u.name}</td>
                                                        <td>{u.age}</td>
                                                        <td>
                                                            <button type="button" class="btn btn-success btn-sm px-2"
                                                                onClick={()=> viewUser(u.name)}
                                                            >                                                                
                                                                <i class="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button type="button" class="btn btn-primary btn-sm px-2">
                                                                <i class="fa-solid fa-eye"></i>
                                                            </button>
                                                            <button type="button" class="btn btn-danger btn-sm px-2"
                                                                onClick={() => deleteUser(u.name)}
                                                            >
                                                                <i class="fa-solid fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default Users;
